import React from 'react';
import Layout from '../components/layout';
import { Link, graphql } from 'gatsby';
import styles from './404.module.scss'
import HeroImageTakeover from '../components/hero-image/hero-image-takeover';

export default ({ data }) => {

  return (
    <Layout>
      <HeroImageTakeover fluid={data.file.childImageSharp.fluid}>
      <div className={`${styles.container}`}>
        <div className={`${styles.contentbox}`}>
          <div className={`has-text-centered container`}>
            <h2 className={`content ${styles.gigantor}`}>404</h2>
            <h1 className={`title is-3 is-spaced has-text-info`}>Oops! Something went wrong.</h1>
              <p className={`content`}>
              We can’t seem to find the page you were looking for. You can go back to the home page or start a new job search.
              </p>
              <Link className={`button is-info`} to='/' id=''>
              Home</Link>&nbsp;&nbsp;<Link className='button is-primary' to='/jobs/' id='jobs-cta'>
              Search jobs</Link>
              <p className={`content ${styles.below}`}>
              Having trouble?  <a href="/contact-us" className={`content ${styles.links}`}>Report a problem</a>
              </p>
              <img src="https://www.catchmarketingservices.com/trackertrustaff404.php" width="1" height="1" border="0" alt=" " className={styles.hidden}/>
          </div>
        </div>
      </div>
      </HeroImageTakeover>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    file(relativePath: { eq: "404/404.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

